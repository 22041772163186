






import { defineComponent, onBeforeUnmount, onMounted, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
let tLoop: number;
export default defineComponent({
	setup() {
		const getWH = (element: HTMLElement): { w: number; h: number } => {
			const res = { w: window.innerWidth, h: window.innerHeight };
			if (!element.getBoundingClientRect) {
				return res;
			}
			const boundingClientRect = element.getBoundingClientRect();
			if (boundingClientRect.width) {
				res.w = boundingClientRect.width;
			} else {
				res.w = boundingClientRect.right - boundingClientRect.left;
			}
			if (boundingClientRect.height) {
				res.h = boundingClientRect.height;
			} else {
				res.h = boundingClientRect.bottom - boundingClientRect.top;
			}
			return res;
		};
		const Dom1: Ref<HTMLCanvasElement | null> = ref(null);
		const canvasInit = () => {
			const c = Dom1.value as HTMLCanvasElement;
			let cx = c.getContext('2d') as CanvasRenderingContext2D;
			const w = (c.width = getWH(c.parentNode || c.parentElement).w);
			const h = (c.height = getWH(c.parentNode || c.parentElement).h);
			// const _w = w * 0.5;
			// const _h = h * 0.5;

			let ci = 0;
			function anim() {
				ci = ci >= 100 ? 0 : ci + 1;
				draw();
			}

			function draw() {
				cx.fillStyle = 'pink';
				cx.strokeStyle = 'pink';
				for (var i = 0; i < 10; i++) {
					var g = cx.createLinearGradient(0, 0, w, h);
					g.addColorStop(0, 'rgba(255,255,255,0.25)');
					g.addColorStop(0.1, 'rgba(255,255,255,0.125)');
					g.addColorStop(0.2, 'rgba(255,255,255,0.1)');
					g.addColorStop(0.4, 'rgba(255,255,255,0.125)');
					g.addColorStop(0.6, 'rgba(255,230,255,0.125)');
					g.addColorStop(0.8, 'rgba(255,205,255,0.125)');
					g.addColorStop(0.9, 'rgba(255,200,205,0.275)');
					g.addColorStop(1, 'rgba(255,255,255,0.125)');
					cx.fillStyle = g;
					cx.beginPath();
					cx.arc(i * 45, i * 32, i * 10, 0, 3.14159265 * 2, true);
					cx.closePath();
					cx.fill();
				}
			}

			// setInterval(anim, 100);
			anim();
		};
		onMounted(canvasInit);
		onBeforeUnmount(() => {
			clearInterval(tLoop);
		});
		return { Dom1 };
	},
});
